import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/parenthome',
        name: 'ParentHome',
        component: () => import(/* webpackChunkName: "home" */ '../views/ParentHome.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        meta: {
            requiresVisitor: true
        }
    },
    {
        path: '/forgotpassword',
        name: 'ForgotPassword',
        component: () => import(/* webpackChunkName: "login" */ '../views/ForgotPassword.vue'),
        meta: {
            requiresVisitor: true
        }
    },
    {
        path: '/students',
        name: 'Students',
        component: () => import(/* webpackChunkName: "students" */ '../views/Students.vue'),
        meta: {
            requiresAuth: true,
            permissions: ['students-view']
        }
    },
    {
        path: '/sabanas',
        name: 'Sabanas',
        component: () => import(/* webpackChunkName: "sabanas" */ '../views/Sabanas.vue'),
        meta: {
            requiresAuth: true,
            permissions: ['sabanas-view']
        }
    },
    {
        path: '/coursesbystudent/:userid/:grade_id',
        name: 'CoursesByStudent',
        component: () => import(/* webpackChunkName: "students" */ '../views/Coursesbystudent.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/studentsbycourse/:courseid',
        name: 'StudentsByCourse',
        component: () => import(/* webpackChunkName: "students" */ '../views/Studentsbycourse.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/logout',
        name: 'Logout',
        component: () => import(/* webpackChunkName: "students" */ '../views/Logout.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/activitiesbycourse/:userid',
        name: 'ActivitiesByCourse',
        component: () => import(/* webpackChunkName: "students" */ '../views/academic/ActivitiesByCourse.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/boletas',
        name: 'Boletas',
        component: () => import(/* webpackChunkName: "students" */ '../views/Boleta.vue'),
        meta: {
            requiresAuth: true,
            permissions: ['boletas-view']
        }
    },
    {
        path: '/coursecatalogue',
        name: 'CoursesCatalogue',
        component: () => import(/* webpackChunkName: "coursecatalogue" */ '../views/CoursesCatalogue.vue'),
        meta: {
            requiresAuth: true,
            permissions: ['courses-view']
        }
    },
    {
        path: '/parentscatalogue',
        name: 'ParentsCatalogue',
        component: () => import(/* webpackChunkName: "parentscatalogue" */ '../views/ParentsCatalogue.vue'),
        meta: {
            requiresAuth: true,
            permissions: ['encargados-view']
        }
    },
    {
        path: '/academicalerts',
        name: 'AcademicAlert',
        component: () => import(/* webpackChunkName: "academicalerts" */ '../views/academic/AcademicAlert.vue'),
        meta: {
            requiresAuth: true,
            permissions: ['alerts-view']
        }
    },
    {
        path: '/roles',
        name: 'Roles',
        component: () => import(/* webpackChunkName: "academicalerts" */ '../views/administration/Roles.vue'),
        meta: {
            requiresAuth: true,
            permissions: ['roles-view']
        }
    },
    {
        path: '/permissions',
        name: 'Permissions',
        component: () => import(/* webpackChunkName: "permissions" */ '../views/administration/Permissions.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "users" */ '../views/administration/Users.vue'),
        meta: {
            requiresAuth: true,
            permissions: ['users-view']
        }
    },
    {
        path: '/accounts',
        name: 'Accounts',
        component: () => import(/* webpackChunkName: "accounts" */ '../views/accounting/Accounts.vue'),
        meta: {
            requiresAuth: true,
            permissions: ['accounts-view']
        }
    },
    {
        path: '/familyproduct',
        name: 'FamilyProduct',
        component: () => import(/* webpackChunkName: "accounts" */ '../views/inventory/FamilyProduct.vue'),
        meta: {
            requiresAuth: true,
            permissions: ['families-view']
        }
    },
    {
        path: '/categoryproduct',
        name: 'CategoryProduct',
        component: () => import(/* webpackChunkName: "accounts" */ '../views/inventory/CategoryProduct.vue'),
        meta: {
            requiresAuth: true,
            permissions: ['categories-view']
        }
    },
    {
        path: '/brands',
        name: 'Brands',
        component: () => import(/* webpackChunkName: "accounts" */ '../views/inventory/Brands.vue'),
        meta: {
            requiresAuth: true,
            permissions: ['brands-view']
        }
    },
    {
        path: '/products',
        name: 'Products',
        component: () => import(/* webpackChunkName: "accounts" */ '../views/inventory/Products.vue'),
        meta: {
            requiresAuth: true,
            permissions: ['products-view']
        }
    },
    {
        path: '/unitmeasurements',
        name: 'UnitMeasurement',
        component: () => import(/* webpackChunkName: "accounts" */ '../views/inventory/UnitMeasurement.vue'),
        meta: {
            requiresAuth: true,
            permissions: ['unitmeasurements-view']
        }
    },
    {
        path: '/services',
        name: 'Services',
        component: () => import(/* webpackChunkName: "accounts" */ '../views/inventory/Services.vue'),
        meta: {
            requiresAuth: true,
            permissions: ['services-view']
        }
    },
    {
        path: '/banks',
        name: 'Banks',
        component: () => import(/* webpackChunkName: "accounts" */ '../views/accounting/Banks.vue'),
        meta: {
            requiresAuth: true,
            permissions: ['banks-view']
        }
    },
    {
        path: '/templates',
        name: 'Templates',
        component: () => import(/* webpackChunkName: "accounts" */ '../views/inventory/Templates.vue'),
        meta: {
            requiresAuth: true,
            permissions: ['templates-view']
        }
    },
    {
        path: '/administrativeunits',
        name: 'AdministrativeUnits',
        component: () => import(/* webpackChunkName: "accounts" */ '../views/accounting/AdministrativeUnits.vue'),
        meta: {
            requiresAuth: true,
            permissions: ['administrativeunit-view']
        }
    },
    {
        path: '/configurationgrades',
        name: 'ConfigurationGrades',
        component: () => import(/* webpackChunkName: "accounts" */ '../views/academic/ConfigurationGrades.vue'),
        meta: {
            requiresAuth: true,
            permissions: ['configcycles-view']
        }
    },
    {
        path: '/recoverypayments',
        name: 'RecoveryPayments',
        component: () => import(/* webpackChunkName: "academicalerts" */ '../views/academic/RecoveryPayments.vue'),
        meta: {
            requiresAuth: true,
            permissions: ['recoverypayments-view']
        }
    },

    {
        path: '/activitiesbystudent/:userid',
        name: 'ActivitiesByStudent',
        component: () => import(/* webpackChunkName: "students" */ '../views/parent/ActivitiesByStudent.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/studentgradecard/:userid',
        name: 'StudentGradeCard',
        component: () => import(/* webpackChunkName: "students" */ '../views/parent/StudentGradeCard.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/studentcalendar/:userid',
        name: 'StudentCalendar',
        component: () => import(/* webpackChunkName: "students" */ '../views/parent/StudentCalendar.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/studentattendance/:userid',
        name: 'Attendance',
        component: () => import(/* webpackChunkName: "students" */ '../views/parent/Attendance.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/firstlogin/:userid',
        name: 'FirstLogin',
        component: () => import(/* webpackChunkName: "students" */ '../views/administration/FirstLogin.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/studentenrollment',
        name: 'StudentEnrollment',
        component: () => import(/* webpackChunkName: "students" */ '../views/academic/StudentEnrollment.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/emailactivity',
        name: 'EmailActivity',
        component: () => import(/* webpackChunkName: "students" */ '../views/administration/EmailActivity.vue'),
        meta: {
            requiresAuth: true,
            permissions: ['emailactivity-view']
        }
    },
    {
        path: '/bulkstudentenrollment',
        name: 'BulkStudentEnrollment',
        component: () => import(/* webpackChunkName: "students" */ '../views/technology/BulkStudentEnrollment.vue'),
        meta: {
            requiresAuth: true,
            permissions: ['sistemas-view']
        }
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "accounts" */ '../views/academic/Dashboard.vue'),
        meta: {
            requiresAuth: true,
            permissions: ['dashboard-view']
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
